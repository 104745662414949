// Trigger deploy

import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ArrowUpTrayIcon, LinkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  PinterestShareButton,
  PinterestIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

interface ShareSocialsProps {
  linkDetails?: { url: string };
  twitterDetails?: {
    url: string;
    title: string;
    via?: string;
    hashtags?: string[];
  };
  facebookDetails?: { url: string; quote: string; hashtag?: string };
  whatsappDetails?: { url: string; title: string };
  redditDetails?: { url: string; title: string };
  pinterestDetails?: { url: string; media: string; description: string };
  emailDetails?: { url: string; subject: string; body: string };
}

function ShareSocials({
  linkDetails,
  twitterDetails,
  facebookDetails,
  whatsappDetails,
  redditDetails,
  pinterestDetails,
  emailDetails,
}: ShareSocialsProps) {
  const onLinkClick = () => {
    navigator.clipboard.writeText(linkDetails.url);
    toast.success("Link copied!");
  };

  return (
    <Popover className="relative w-full">
      <Popover.Button className="group/share flex w-full items-center justify-center rounded-md py-1 text-gray-500 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:py-2">
        <span className="sr-only">Share</span>

        <ArrowUpTrayIcon className="h-4 w-4 text-gray-500 group-hover/share:text-orange-600 sm:h-5 sm:w-5" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-fit -translate-x-[calc(100%-3rem)] px-4 sm:-translate-x-3/4 md:-translate-x-2/3 lg:-translate-x-1/2">
          <div className="flex w-full space-x-2 rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            {linkDetails ? (
              <button onClick={onLinkClick}>
                <LinkIcon className=" h-8 cursor-pointer rounded-full bg-gray-200 p-2 text-gray-500 hover:bg-orange-300  hover:text-white sm:w-8" />
              </button>
            ) : null}
            {twitterDetails ? (
              <TwitterShareButton {...twitterDetails}>
                <TwitterIcon
                  size={32}
                  round={true}
                  className="cursor-pointer hover:opacity-80 focus:opacity-80"
                />
              </TwitterShareButton>
            ) : null}
            {facebookDetails ? (
              <FacebookShareButton {...facebookDetails}>
                <FacebookIcon
                  size={32}
                  round={true}
                  className="cursor-pointer hover:opacity-80 focus:opacity-80"
                />
              </FacebookShareButton>
            ) : null}
            {whatsappDetails ? (
              <WhatsappShareButton {...whatsappDetails}>
                <WhatsappIcon
                  size={32}
                  round={true}
                  className="cursor-pointer hover:opacity-80 focus:opacity-80"
                />
              </WhatsappShareButton>
            ) : null}
            {redditDetails ? (
              <RedditShareButton {...redditDetails}>
                <RedditIcon
                  size={32}
                  round={true}
                  className="cursor-pointer hover:opacity-80 focus:opacity-80"
                />
              </RedditShareButton>
            ) : null}
            {pinterestDetails ? (
              <PinterestShareButton {...pinterestDetails}>
                <PinterestIcon
                  size={32}
                  round={true}
                  className="cursor-pointer hover:opacity-80 focus:opacity-80"
                />
              </PinterestShareButton>
            ) : null}
            {emailDetails ? (
              <EmailShareButton {...emailDetails}>
                <EmailIcon
                  size={32}
                  round={true}
                  className="cursor-pointer hover:opacity-80 focus:opacity-80"
                />
              </EmailShareButton>
            ) : null}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default ShareSocials;
