import Link from "next/link";

import { ALLOWED_IFRAMES } from "constants/allowedIframes";
import { withLink } from "./textUtils";

const interweaveTransform = (node: HTMLElement, children) => {
  // Interweave transform to allow iframes from allowed iframes
  if (node.tagName === "IFRAME") {
    const src = node.getAttribute("src");
    const isAllowed =
      !!src &&
      ALLOWED_IFRAMES.some((allowedIframe) => src.includes(allowedIframe));

    if (isAllowed) {
      const attributes = {
        width: node.getAttribute("width") || "100%",
        height: node.getAttribute("height") || "100%",
        frameBorder: 0,
        allowFullScreen: true,
        allow:
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      };

      return <iframe src={src} {...attributes} />;
    }
  }

  // Interweave transform sup tagName to add id and link to footnote anchor (#footnote-[id])
  if (node.tagName === "SUP") {
    const id = node.getAttribute("data-id");
    const superscript = node.textContent;

    if (id && superscript) {
      return (
        <Link
          id={`${id}`}
          href={`#footnote-${id}`}
          className=" hover:text-orange-600"
          style={{ textDecoration: "none" }}
        >
          <sup className="-z-10">{superscript}</sup>
        </Link>
      );
    }
  }
};

export { interweaveTransform };
