import {
  MagnifyingGlassIcon,
  HomeIcon,
  UsersIcon,
  UserGroupIcon,
  WrenchIcon,
  PuzzlePieceIcon,
  EllipsisHorizontalCircleIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";

export const NAVIGATION_OPTIONS = [
  { name: "Home", icon: HomeIcon, href: "/home" },
  {
    name: "Explore",
    icon: MagnifyingGlassIcon,
    href: "/explore",
  },
  { name: "Profile", icon: UsersIcon, href: "/login" },
  {
    name: "More",
    icon: EllipsisHorizontalCircleIcon,
    options: [
      { name: "Subscriptions", icon: UserGroupIcon, href: "/subscriptions" },
      { name: "Subscribers", icon: FaceSmileIcon, href: "/subscribers" },
      { name: "Divider" },
      { name: "Extensions", icon: PuzzlePieceIcon, href: "/extensions" },
      { name: "Settings", icon: WrenchIcon, href: "/settings" },
    ],
  },
];
