export const MAX_UPLOAD_MEGA_BYTES = 500;
export const MAX_FREE_UPLOAD_MEGA_BYTES = 5;

export const USER_VALIDATIONS = {
  contactNameMaxLength: 100,
  contactEmailMaxLength: 100,
  contactMessageMaxLength: 1000,
  usernameMinLength: 3,
  usernameMaxLength: 30,
  bioMaxLength: 300,
  nameMinLength: 2,
  nameMaxLength: 40,
  birthYearMin: 1900,
  birthYearMinAge: 13,
  shortTextPostMaxLength: 300,
  shortTextPostWarningLimit: 30,
  articlePostMaxLength: 25000,
  articleFreePostMaxLength: 2500,
  articleTitleMaxLength: 300,
  maxUploadSize: MAX_UPLOAD_MEGA_BYTES * 1048576,
  maxFreeUploadSize: MAX_FREE_UPLOAD_MEGA_BYTES * 1048576,
  backButtonTextMaxLength: 50,
  maximumPinnedPosts: 6,
  metaTitleMaxLength: 60,
  metaDescriptionMaxLength: 160,
};

export const SUPPORTED_AUDIO_FORMATS = [
  ".mp3",
  ".wav",
  ".ogg",
  ".flac",
  ".m4a",
  ".aac",
];
export const SUPPORTED_IMAGE_FORMATS = [".jpg", ".jpeg", ".png", ".gif"];
export const SUPPORTED_VIDEO_FORMATS = [".mp4", ".mov", ".webm", ".avi"];

export const SUPPORTED_AUDIO_MIME_TYPES = [
  "audio/mpeg",
  "audio/wav",
  "audio/ogg",
  "audio/aac",
  "audio/m4a",
  "audio/flac",
];

export const SUPPORTED_IMAGE_MIME_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
];

export const SUPPORTED_VIDEO_MIME_TYPES = [
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/x-msvideo",
];
