import { Fragment, useState, useEffect, useContext } from "react";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import Image from "next/image";
import { PuzzlePieceIcon } from "@heroicons/react/24/outline";
import { Popover } from "@headlessui/react";

import { useAuth } from "context/AuthContext";
import { db } from "utils/firebase";
import { handleExtensionActivate } from "pages/api/extension";
import { ExtensionStandardModalContext } from "components/Post/StandardModal";
import { ExtensionArticleModalContext } from "components/Post/ExpandedModal";
import { BASE_URL } from "constants/redirects";

interface ExtensionIconProps {
  extensionId: string;
  type: "short" | "article";
}

const ExtensionIcon = ({ extensionId, type }: ExtensionIconProps) => {
  const { user } = useAuth();

  const { topic, setContent, handleEditPending } = useContext(
    type === "short"
      ? ExtensionStandardModalContext
      : ExtensionArticleModalContext
  );

  const [extensionActive, setExtensionActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [extension, extensionLoading] = useDocument(
    doc(db, `extensions`, extensionId)
  );
  const extensionData = extension?.data();

  const [userExtension, userExtensionLoading] = useDocument(
    doc(db, `users/${user?.uid || "0"}/extensions`, extensionId)
  );
  const userExtensionData = userExtension?.data();

  useEffect(() => {
    if (userExtensionData?.active === true) setExtensionActive(true);
    else setExtensionActive(false);
  }, [userExtensionData]);

  const handleExtensionClick = async () => {
    if (extensionData?.apiUrl) {
      handleEditPending(true);
      setIsDisabled(true);
      //Call extension API
      await fetch(
        `/api/extensions/${extensionData.apiUrl}?type=${type}${
          topic ? `&topic=${topic}` : ""
        }`
      )
        .then(async (res) => {
          const data = await res.json();
          const content = data?.response?.choices?.[0]?.message?.content;
          if (content) setContent(content);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsDisabled(false);
        });
    } else {
      // Toggle extension
      setExtensionActive(!extensionActive);
      handleExtensionActivate(extensionId, user.uid);
    }
  };

  const renderIconImage = () => {
    if (extensionLoading || userExtensionLoading) return;
    if (extensionActive && extensionData?.activeIconSrc)
      return (
        <Image
          className="h-6 w-6"
          width={24}
          height={24}
          alt={extensionData?.title}
          src={extensionData?.activeIconSrc}
          title={extensionData?.title}
        />
      );
    if (!extensionActive && extensionData?.inactiveIconSrc)
      return (
        <Image
          className="h-6 w-6"
          width={24}
          height={24}
          alt={extensionData?.title}
          src={extensionData?.inactiveIconSrc}
          title={extensionData?.title}
        />
      );
    return <PuzzlePieceIcon className="h-6 w-6  text-gray-600" />;
  };

  return (
    <div className="mx-1 flex flex-col items-center justify-center rounded-full hover:bg-gray-100">
      <button
        className="cursor-pointer p-1 disabled:cursor-progress"
        onClick={handleExtensionClick}
        disabled={isDisabled}
      >
        {renderIconImage()}
      </button>
    </div>
  );
};

export default ExtensionIcon;
